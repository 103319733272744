import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql, withPrefix } from "gatsby";
import favicon from "../images/wattman_mobile.webp";

import { useIntl, Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

const SEO = ({ title, description, image, pathname, keywords, author, intl }) => (
  <StaticQuery
    query={detailsQuery}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          titleTemplate,
          defaultKeywords,
          author,
          siteUrl,
          defaultImage,
          twitterUsername
        }
      }
    }) => {
      const seo = {
        title: title ? title : defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || defaultKeywords,
        author: author,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`
      };

      return (
        <Helmet title={seo.title} htmlAttributes={{ lang: "en"}} titleTemplate={titleTemplate}>
          <meta name="description" content={description ? seo.description : intl.formatMessage({ id: "Home_intro" })} />
          <meta name="image" content={seo.image} />
          <meta name="keywords" content={keywords ? seo.keywords : intl.formatMessage({ id: "keywords" })} />
          <meta
            name="google-site-verification"
            content="F5KR4_eCcDy5CAAvXG3HTm0-BdD7NoaXR6WtfibWVss"
          />
          <link rel="icon" href={favicon}></link>
          {seo.url && <meta property="og:url" content={seo.url} />}
          {seo.title && <meta property="og:title" content={seo.title} />}
          <meta name="author" content={seo.author} />
          {seo.description && (
            <meta
              property="og:description"
              content={description ? seo.description : intl.formatMessage({ id: "Home_intro" })}
            />
          )}
          {seo.image && <meta property="og:image" content={seo.image} />}
          <meta name="twitter:card" content="summary_large_image" />
          {twitterUsername && (
            <meta name="twitter:creator" content={twitterUsername} />
          )}
          {seo.title && <meta name="twitter:title" content={seo.title} />}
          {seo.description && (
            <meta
              name="twitter:description"
              content={description ? seo.description : intl.formatMessage({ id: "Home_intro" })}
            />
          )}
          {seo.image && <meta name="twitter:image" content={seo.image} />}

          <script src={withPrefix('script.js')} type="text/javascript" />
        </Helmet>
      );
    }}
  />
);

export default injectIntl(SEO);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  keywords: null,
  image: null,
  pathname: null
};

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultKeywords: keywords
        siteUrl: url
        author
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
